<template>
  <div class="word-teaser">
    <div class="word-teaser-content">
      <div class="">
        <b-row class="align-items-center">
          <b-col cols="12" class="text-center mb-3">
            <a class="pointer" @click.stop="playAudio">
              <AudioSvg class="svg-blue svg-icon-lg"/>
            </a>
          </b-col>
          <b-col cols="12 mb-3">
            <div v-if="word.description" class="word-description">
              {{ word.description }}
            </div>
            <div class="text-center" v-else>
              <img :src="word.image ? word.image : `/images/logo_h.svg`"
                   class="word-teaser-img"
                   alt=""/>
            </div>
          </b-col>
          <b-col cols="12" v-if="['memorize'].includes(mode)">
            <div class="word text-center title">
              {{ word.word }}
            </div>
            <div v-if="word.transcription" class="text-muted mt-2">
              [{{ word.transcription }}]
            </div>
          </b-col>
          <b-col cols="12" v-if="['assign'].includes(mode)">
            <span class="response-box" :class="{
              right: showAnswer && response && response.word === word.word,
              wrong: showAnswer && response && response.word !== word.word
            }">
              {{ response ? response.word : '' }}
            </span>
          </b-col>
          <b-col cols="12" v-if="['letters'].includes(mode)">
            <div>
              <span v-for="(w, wind) in word.word"
                    class="letter-blank"
                    :class="{
                      right: finished && filledLetters[wind] && w === filledLetters[wind].letter,
                      wrong: finished && filledLetters[wind] && w !== filledLetters[wind].letter 
                    }"
                    @click="dropLetter(wind)"
                    :key="`let-${wind}`">
                  <template v-if="filledLetters[wind]">{{ filledLetters[wind].letter }}</template>
              </span>
            </div>
            <div class="mt-3">
              <span v-for="(w, wind) in shuffled"
                    class="letter-drag"
                    :class="{ active: selectedLetter && selectedLetter.index === wind }"
                    @click="selectLetter(w, wind)"
                    :key="`let-r-${wind}`">
                <template v-if="!filledLetters.filter(x => x && x.index === wind).length">
                  {{ w }}  
                </template>
              </span>
            </div>
          </b-col>
          <b-col cols="12" v-if="['choose'].includes(mode)">
            <b-row>
              <b-col cols="6" v-for="(w, wind) in shuffled" :key="`sh-r-${wind}`" class="mb-10">
                <div class="word-option" 
                     :class="{
                        right: selectedWord && selectedWord.word === w.word && w.word === word.word,
                        wrong: selectedWord && selectedWord.word === w.word && w.word !== word.word      
                     }" 
                     @click="selectWord(w)">
                  <span class="d-inline-block">{{ w.word }}</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import AudioSvg from '@/assets/svg/audio4.svg'
import { shuffleArray } from "../../helpers/shuffleArray"
import {generateAudio} from "../../helpers/generateAudio"

export default {
  name: "WordCardTeaser",
  components: {
    AudioSvg,
  },
  props: {
    word: { type: Object },
    mode: { type: String, default: 'memorize' },
    candelete: { type: Boolean, default: false },
    canedit: { type: Boolean, default: false },
    otherCards: {},
    response: {},
    showAnswer: {}
  },
  data() {
    return {
      audio: null,
      selectedLetter: null,
      selectedWord: null,
      filledLetters: [],
      shuffled: []
    }
  },
  computed: {
    finished() {
      return this.shuffled.length === this.filledLetters.filter(x => x && x.letter).length
    }
  },
  methods: {
    async playAudio() {
      if(!this.word.audio_url) {
        let audioUrl = await generateAudio(this.word.word, this.word.id)
        if(audioUrl) this.word.audio_url = audioUrl
        return
      }
      if(this.audio && !this.audio.paused) {
        this.audio.pause()
        this.audio = null
        return
      }
      if(!this.audio) {
        this.audio = new Audio(this.word.audio_url)
      }
      this.audio.play()
    },
    selectLetter(letter, index) {
      if(this.filledLetters.filter(x => x && x.index === index).length) return
      if(this.selectedLetter && this.selectedLetter.index === index) {
        this.selectedLetter = null
        return
      }
      this.selectedLetter = { letter, index }
    },
    dropLetter(index) {
      if(!this.selectedLetter) {
        if(this.filledLetters[index] && this.filledLetters[index].letter) {
          this.$set(this.filledLetters, index, null)    
        }
        return
      }
      this.$set(this.filledLetters, index, this.selectedLetter)
      this.selectedLetter = null
    },
    selectWord(word) {
      this.selectedWord = word
    },
    initiate() {
      this.audio = null
      this.selectedLetter = null
      this.selectedWord = null
      this.filledLetters = []
      this.shuffled = []
      if(this.mode === 'letters') {
        this.shuffled = shuffleArray(Array.from(this.word.word))
      }
      if(this.mode === 'choose') {
        let cards = this.otherCards.filter(x => x.id !== this.word.id).sort(() => Math.random() - Math.random()).slice(0, 3)
        cards.push(this.word)
        this.shuffled = shuffleArray(cards)
      }
    }
  },
  watch: {
    word: {
      handler() {
        this.initiate()
      }
    },
    mode: {
      handler() {
        this.initiate()
      }
    }
  },
  mounted() {
    this.initiate()
  },

}
</script>

<style scoped lang="scss">
.word-teaser {
  max-width: 480px;
  margin: 0 auto;
  box-shadow: 0px 2px 16px -4px rgba(131, 136, 140, 0.23);
  height: 100%;
  padding: 20px;
  position: relative;
  border-radius: 16px;
  .word-description {
    color: $text-themed;
    min-height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F8;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    min-width: calc(min(100%, 240px));
    margin: 0 auto;
  }
  .word-teaser-img {
    height: 130px;
    width: 100%;
    object-fit: contain;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 4px;
  }
  .letter-blank, .letter-drag {
    width: 38px;
    height: 38px;
    vertical-align: middle;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    cursor: pointer;
    font-size: 21px;
    font-weight: bold;
  }
  .letter-blank {
    background: #FFFFFF;
    border: 1px solid #E9EBF0;
    &.right {
      border-color: $text-success;
    }
    &.wrong {
      border-color: $theme-red;
    }
  }
  .letter-drag {
    background: #F5F6F8;
    color: #7E828C;
    &.active {
      background-color: $gray-fill;
    }
  }
  .word {
    &.title {
      font-weight: bold;
      font-size: 28px;
    }
    &.title-md {
      font-weight: bold;
      font-size: 18px;
    }
  }
  &-content {
    .b-bottom {
      padding-bottom: 12px;
      border-bottom: 1px solid $border-gray;
      .word {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .response-box {
    background: #FFFFFF;
    border: 1px solid #DADFEB;
    border-radius: 10px;
    min-width: 116px;
    padding: 0 8px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    &.right {
      border-color: $text-success;
    }
    &.wrong {
      border-color: $theme-red;
    }
  }
  .word-option {
    background: #F5F6F8;
    border-radius: 8px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7E828C;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    > span:first-letter {
      text-transform: uppercase;  
    }
    &.right {
      background: #80CA78;
      color: #fff;
    }
    &.wrong {
      background: #EB5164;
      color: #fff;
    }
  }
}


@media(max-width: 768px) {
  .word-teaser {
    padding: 12px;
    &-content {
      //padding: 8px 8px 0 8px;
      padding: 0;
      border-radius: 14px;
    }
    .word {
      &.title {
        font-size: 20px;
      }
    }
    .word-teaser-img {
      max-width: 240px !important;
    }
  }
}

</style>






