<template>
  <div class="position-relative word-searched pointer">
    <b-row class="align-items-center">
      <b-col cols="auto" v-if="view === 'constructor'">
        <div class="checkbox mt-1">
          <input type="checkbox"
                 :checked="cards.find(x => x.id === word.id)"
                 value="1"/>
          <label></label>
        </div>
      </b-col>
      <b-col cols="auto">
        <img class="word-img" :src="word.image ? word.image : `/images/logo_h.svg`" alt=""/>
      </b-col>
      <b-col>
        <div class="pl-1 text-capitalize-first">
          {{ word.word }}<span v-if="word.transcription" class="text-muted ml-3">[{{ word.transcription }}]</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "WordCardTeaserRow",
  props: {
    word: { type: Object },
    cards: { },
    view: {}
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    
  },
  watch: {
    
  },
  mounted() {
  },

}
</script>

<style scoped lang="scss">
.word-searched {
  padding: 12px 0;
  border-bottom: 1px solid #EDEFF4;
  .word-img {
    width: 72px;
    height: 48px;
    object-fit: cover;
    border-radius: 4px;
  }
}

</style>






