<template>
  <div class="word-teaser">
    <div class="word-teaser-content">
      <div class="">
        <b-row class="align-items-center">
          <b-col cols="auto" class="text-center">
            <a class="pointer" @click.stop="playAudio">
              <AudioSvg class="svg-blue svg-icon-md"/>
            </a>
          </b-col>
          <b-col cols="auto">
            <div class="text-center px-2">
              <img :src="word.image ? word.image : `/images/logo_h.svg`"
                   class="word-teaser-img"
                   alt=""/>
            </div>
          </b-col>
          <b-col col>
            <div class="word text-capitalize-first">
              {{ word.word }}<span v-if="word.transcription" class="text-muted ml-3">[{{ word.transcription }}]</span>
            </div>
          </b-col>
          <b-col cols="auto">
            <a class="pointer edit-btn  d-inline-flex align-middle"
               v-if="canedit"
               @click="edit">
              <EditSvg class="svg-icon-sm svg-darkgray"/>
            </a>
            <a class="pointer delete-icon d-inline-block align-middle ml-2"
               v-if="candelete"
               @click="del">
              <CloseSvg class="svg-icon-lg"/>
            </a>
          </b-col>
          
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import CloseSvg from '@/assets/svg/close2.svg'
import EditSvg from '@/assets/svg/pencil.svg'
import AudioSvg from '@/assets/svg/audio4.svg'
import {generateAudio} from "../../helpers/generateAudio"

export default {
  name: "WordCardTeaserConstructor",
  components: {
    CloseSvg,
    AudioSvg,
    EditSvg
  },
  props: {
    word: { type: Object },
    mode: { type: String, default: 'memorize' },
    candelete: { type: Boolean, default: false },
    canedit: { type: Boolean, default: false },
    response: {},
    showAnswer: {}
  },
  data() {
    return {
      audio: null,
      selectedLetter: null,
      filledLetters: [],
      shuffled: []
    }
  },
  methods: {
    del() {
      this.$emit('delete')
    },
    edit() {
      this.$store.commit('setCard', this.word)
      this.$bvModal.show('addcardmodal')
    },
    async playAudio() {
      if(!this.word.audio_url) {
        let audioUrl = await generateAudio(this.word.word, this.word.id)
        if(audioUrl) this.word.audio_url = audioUrl 
        return
      }
      if(this.audio && !this.audio.paused) {
        this.audio.pause()
        this.audio = null
        return
      }
      if(!this.audio) {
        this.audio = new Audio(this.word.audio_url)
      }
      this.audio.play()
    },
    initiate() {
      this.audio = null
      this.selectedLetter = null
      this.filledLetters = []
      this.shuffled = []
    }
  },
  watch: {
    word: {
      handler() {
        this.initiate()
      }
    }
  },
  mounted() {
    this.initiate()
  },

}
</script>

<style scoped lang="scss">
.word-teaser {
  margin: 0 auto;
  padding: 12px;
  position: relative;
  border-radius: 7px;
  border-bottom: 1px solid #EDEFF4;
  .word-teaser-img {
    height: 48px;
    width: 72px;
    object-fit: cover;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 4px;
  }
  .delete-icon {
    //right: -12px;
    //top: -12px;
  }
  .edit-btn {
    //position: absolute;
    //right: 20px;
    //top: -12px;
    background: #F8F9FB;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    .b-bottom {
      padding-bottom: 12px;
      border-bottom: 1px solid $border-gray;
      .word {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}


@media(max-width: 768px) {
  //.word-teaser {
  //  &-content {
  //    padding: 8px 8px 0 8px;
  //    border-radius: 14px;
  //  }
  //}
}

</style>






